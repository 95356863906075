import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";
const { persistAtom } = recoilPersist();

const userState = atom({
  key: "userState",
  default: {
    isLoggedIn: false,
    username: "",
    naverPlace: false,
    // naverPlaceSlow: false,
    smartStore: false,
    smartStoreActive: false,
    ftcStore: false,
  },
  effects_UNSTABLE: [persistAtom],
});

export default userState;
