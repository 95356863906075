import React from 'react'

const ClickToCall = ({ tel, setIframeUrl }) => {
    return (
        <div onClick={() => {
            console.log("click to call clicked", tel)
            setIframeUrl(`http://127.0.0.1:7071/clickcall?TelNo=${tel.replace(/-/gi, "")}`)
        }} className="cursor-pointer" target="_blank">{tel}</div>
    )
}

export default ClickToCall