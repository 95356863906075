import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  API_URL,
  PAGE_SIZE,
} from "../constants";
import {
  Table,
  Input,
  message,
  Tooltip,
  DatePicker,
  Button,
} from "antd";

import moment from "moment";
import "moment/locale/ko";
import useSWR, { useSWRConfig } from "swr";
import { useRecoilState } from "recoil";
import userState from "../atoms/user";
import { useHistory } from "react-router";
import SmartStorePageLayout from "./SmartStorePageLayout";
import { ContractStatus } from '../components/ContractStatus'
import { getUniqueById } from "../utils";
import LoadingIndicator from "../components/LoadingIndicator";
import ClickToCall from "../components/ClickToCall";
import HiddenIframe from "../components/HiddenIframe";
import { fetcher } from "../utils/swr";
import StatusButtonGroup from "../components/StatusButtonGroup";
import DateTimeWithFromNow from "../components/DateTimeWithFromNow";
import { toast } from "react-toastify";
import DescriptionInput from "../components/DescriptionInput";

const SmartStoreWithoutProductTotal = () => {
  const { RangePicker } = DatePicker;

  const [storesTotal, setStoresTotal] = useState([]);
  const [searchKeywordTotal, setSearchKeywordTotal] = useState("");
  const [filteredStoresTotal, setFilteredStoresTotal] = useState([]);
  const [selectedStartDate, setSelectedStartDate] = useState("");
  const [selectedEndDate, setSelectedEndDate] = useState("");

  const history = useHistory();
  const [user, setUser] = useRecoilState(userState);

  const [iframeUrl, setIframeUrl] = useState(null);


  const backToLogin = () => {
    setUser({
      isLoggedIn: false,
      naverPlace: false,
      smartStore: false,
      ftcStore: false
    });
    message.error("로그인이 만료되었습니다. 다시 로그인해주세요.");
    history.replace("/login");
  };

  const onStatusChange = async (row, status) => {


    if (status === 3) {
      if (!window.confirm("계약 업체가 확실합니까?")) {
        return;
      }
    }

    const checkedData = filteredStoresTotal.map((store) =>
      store.id === row.id
        ? { ...store, status: status }
        : store
    )
    // console.log(checkedData)
    // mutate("smartstore/withoutproduct/total", { "stores_without_product": getUniqueById(checkedData) })
    setStoresTotal(getUniqueById(checkedData));

    await axios.post(
      `${API_URL}/smartstore/store_without_product/check`,
      {
        id: row.id,
        status: status
      },
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`
        }
      }
    );
  }

  const handleSearch = async () => {
    let tempFilteredStoresTotal = storesTotal;

    if (selectedStartDate && selectedEndDate) {
      tempFilteredStoresTotal = tempFilteredStoresTotal.filter(
        (place) =>
          moment(place.created_at).isSameOrAfter(selectedStartDate, "day") &&
          moment(place.created_at).isSameOrBefore(selectedEndDate, "day")
      );
    }

    if (searchKeywordTotal) {
      tempFilteredStoresTotal = tempFilteredStoresTotal.filter((place) =>
        place.store_name.includes(searchKeywordTotal) || place.phone_number === searchKeywordTotal
      );
    }

    setFilteredStoresTotal(tempFilteredStoresTotal);
  };

  useEffect(() => {
    handleSearch();
  }, [selectedStartDate, selectedEndDate, storesTotal]);


  const onDescriptionChange = async (description, id) => {
    const checkedData = storesTotal.map((store) =>
      store.id === id ? { ...store, description: description } : store
    );
    setStoresTotal(checkedData);
    await axios.post(
      `${API_URL}/smartstore/withoutproduct/check-description`,
      {
        id: id,
        description: description,
      },
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    toast.info("비고가 입력되었습니다.", {
      position: "bottom-right",
    })
  }

  // const handleSearch = async () => {
  //   if (searchKeyword) {
  //     setFilteredStores(
  //       stores.filter((store) =>
  //         store.store_name.includes(searchKeyword.trim())
  //       )
  //     );
  //   } else {
  //     setFilteredStores(stores);
  //   }
  // };



  const { data, error, isValidating } = useSWR(`smartstore/withoutproduct/total`, fetcher);

  const { mutate } = useSWRConfig();

  useEffect(() => {
    if (error) {
      backToLogin();
      console.log(`GET_SMART_STORES_WITHOUT_PRODUCT_ERROR: ${error}`);
    }

    if (data) {
      console.log(data)
      setStoresTotal(getUniqueById(data.stores_without_product));
      handleSearch();
      // setFilteredStoresTotal(newStores);
    }
  }, [data]);

  const columns = [
    {
      title: "상호명",
      dataIndex: "store_name",
      key: "store_name",
      render: (name, row) => (
        <a
          href={"https://smartstore.naver.com/" + row.store_url}
          target="_blank"
          rel="noreferrer"
        >
          <span>{name}</span>
        </a>
      )
    },
    {
      title: "상태",
      dataIndex: "status",
      key: "status",
      align: "center",
      filters: [
        {
          text: '미사용',
          value: 0,
        },
        {
          text: '사용',
          value: 1,
        },
        {
          text: '부재중',
          value: 2,
        },
        {
          text: '계약',
          value: 3,
        }
      ],
      onFilter: (value, record) => {
        // console.log(value, record)
        if (!record.status) {
          return false;
        }
        return record.status === value
      },
      render: (status, row) => (
        <div className="flex justify-center">
          <StatusButtonGroup status={status} row={row} onStatusChange={onStatusChange} />
        </div>
      )
    },
    {
      title: "전화번호",
      dataIndex: "phone_number",
      key: "phone_number",
      align: "center",
      render: (tel) => (
        <Tooltip title={tel} overlayInnerStyle={{ fontSize: "2em" }}>
          <ClickToCall setIframeUrl={setIframeUrl} tel={tel} />
        </Tooltip>
      )
    },
    {
      title: "통신판매",
      dataIndex: "online_marketing_number",
      key: "online_marketing_number",
      align: "center",
      render: (online_marketing_number) => (
        <div>{online_marketing_number ? online_marketing_number : "-"}</div>
      )
    },
    {
      title: "사업자등록번호",
      dataIndex: "identity",
      key: "identity",
      align: "center",
      render: (identity) => (
        <div>{identity ? identity : "-"}</div>
      )
    },
    {
      title: "비고",
      dataIndex: "description",
      key: "description",
      align: "center",
      render: (description, row) => (
        <DescriptionInput store={row} onDescriptionChange={onDescriptionChange}></DescriptionInput>
      )
    },
    {
      title: "등록시간",
      dataIndex: "created_at",
      key: "created_at",
      align: "center",
      defaultSortOrder: "descend",
      sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
      render: (date) => (
        <>
          <DateTimeWithFromNow date={date} />
        </>
      )
    }
  ];

  return (
    <SmartStorePageLayout>
      <HiddenIframe url={iframeUrl} />
      <ContractStatus stores={storesTotal} />

      <div className="flex my-2 space-x-2">
        <RangePicker
          style={{ minWidth: 300 }}
          onChange={(dateRange) => {
            if (dateRange) {
              const startDate = dateRange[0];
              const endDate = dateRange[1];
              if (startDate && endDate) {
                setSelectedStartDate(startDate);
                setSelectedEndDate(endDate);
              } else {
                setSelectedStartDate("");
                setSelectedEndDate("");
              }
            } else {
              setSelectedStartDate("");
              setSelectedEndDate("");
            }
          }}
          value={
            selectedStartDate && selectedEndDate
              ? [moment(selectedStartDate), moment(selectedEndDate)]
              : ["", ""]
          }
        />

        <Input
          placeholder="검색어를 입력하세요."
          onChange={(e) => {
            setSearchKeywordTotal(e.target.value.trim());
          }}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              handleSearch();
            }
          }}
        />
        <Button onClick={handleSearch}>검색</Button>
      </div>
      {data ?
        <Table
          size="small"
          dataSource={filteredStoresTotal}
          columns={columns}
          rowKey={(record) => record.id}
          pagination={{ pageSize: PAGE_SIZE }}
        />
        : <LoadingIndicator />
      }
    </SmartStorePageLayout >
  );
};

export default SmartStoreWithoutProductTotal;
