import React from 'react'

export const ContractStatus = ({ stores }) => {
  if (!stores) {
    return <></>
  }

  return (
    <div className="space-x-4 my-4 bg-gray-100 px-2 py-2 rounded-md text-xs">
      <span>총: {stores.length}</span>
      <span>미연락: {stores.filter(store => store.status === 0 || !store.status).length}</span>
      <span>연락: {stores.filter(store => store.status === 1).length}</span>
      <span>부재중: {stores.filter(store => store.status === 2).length}</span>
      <span>계약: {stores.filter(store => store.status === 3).length}</span>
      {/* {
        stores.filter(store => store.status === 2).length !== 0 && stores.filter(store => store.status === 1).length !== 0 &&
        <span>첫콜: {((1 - (stores.filter(store => store.status === 2).length / stores.filter(store => store.status === 1 || store.status === 2).length)) * 100).toFixed(0)}%</span>
      } */}
    </div>
  )
}
