import React from "react";
import { Menu } from "antd";
import { Link, useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";
import userState from "../atoms/user";

const menus = [
  // {
  //   name: "네이버지도",
  //   path: "/naverplace",
  // },
  // {
  //   name: "st",
  //   path: "/smartstore",
  // },
  // {
  //   name: "통신판매",
  //   path: "/ftc",
  // },
];

const Header = () => {
  const history = useHistory();

  const [user, setUser] = useRecoilState(userState);

  const handleLogout = () => {
    setUser({
      isLoggedIn: false,
      naverPlace: false,
      smartStore: false,
      ftcStore: false,
    });
    localStorage.removeItem("token");
    history.replace("/login");
  };

  return (
    <div>
      <Menu
        theme="dark"
        mode="horizontal"
        defaultSelectedKeys={history.location.pathname}
      >
        {menus.map((menu) => {
          return (
            <Menu.Item key={menu.path}>
              <Link to={menu.path}>
                <span className="text-lg my-2">{menu.name}</span>
              </Link>
            </Menu.Item>
          );
        })}
        <>
          {!user.isLoggedIn ? (
            <Menu.Item key="login" style={{ marginLeft: "auto" }}>
              <Link to="/login">
                <span className="text-lg my-2">로그인</span>
              </Link>
            </Menu.Item>
          ) : (
            <Menu.Item
              key="logout"
              onClick={handleLogout}
              style={{ marginLeft: "auto" }}
            >
              <span className="my-2">logout ({user.username})</span>
            </Menu.Item>
          )}
        </>
      </Menu>
    </div>
  );
};

export default Header;
