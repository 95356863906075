import "./App.css";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import { RecoilRoot } from "recoil";
import SmartStoreWithoutProductTodayPage from "./pages/SmartStoreWithoutProductTodayPage";
import SmartStoreWithoutProductTotal from "./pages/SmartStoreWithoutProductTotal";
import SmartStoreWithProductToday from "./pages/SmartStoreWithProductToday";
import SmartStoreWithProductTotal from "./pages/SmartStoreWithProductTotal";
import SmartStoreWithoutProductContact from "./pages/SmartStoreWithoutProductContact";
import SmartStoreWithProductContact from "./pages/SmartStoreWithProductContact";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <RecoilRoot>
      <Router>
        <Switch>
          <Route exact path="/" component={SmartStoreWithoutProductTodayPage} />
          <Route path="/login" component={LoginPage} />
          {/* <Route exact path="/naverplace" component={NaverPlaceTodayPage} />
          <Route
            exact
            path="/naverplace/total"
            component={NaverPlaceTotalPage}
          />
          <Route
            exact
            path="/naverplace/contact"
            component={NaverPlaceContactPage}
          /> */}
          <Route
            exact
            path="/smartstore"
            component={SmartStoreWithoutProductTodayPage}
          />
          <Route
            exact
            path="/smartstore/withoutproduct/total"
            component={SmartStoreWithoutProductTotal}
          />
          <Route
            exact
            path="/smartstore/withoutproduct/contact"
            component={SmartStoreWithoutProductContact}
          />
          <Route
            exact
            path="/smartstore/withproduct/today"
            component={SmartStoreWithProductToday}
          />
          <Route
            exact
            path="/smartstore/withproduct/total"
            component={SmartStoreWithProductTotal}
          />
          <Route
            exact
            path="/smartstore/withproduct/contact"
            component={SmartStoreWithProductContact}
          />
          {/* <Route
            exact
            path="/smartstore/active"
            component={SmartStoreActiveToday}
          />
          <Route
            exact
            path="/smartstore/active/total"
            component={SmartStoreActiveTotal}
          />
          <Route
            exact
            path="/smartstore/active/contact"
            component={SmartStoreActiveContact}
          />
          <Route exact path="/ftc" component={FTCPage} />
          <Route exact path="/ftc/total" component={FTCPage} />
          <Route exact path="/coupang" component={Coupang} /> */}

          {/* <Route path="/settings" component={SettingsPage} /> */}
        </Switch>
      </Router>
      <ToastContainer />
    </RecoilRoot>
  );
}

export default App;
