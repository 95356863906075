// const API_URL = "http://localhost:3334/api";
// export const API_URL = "http://49.247.21.169:3334/api";
export const API_URL = "http://49.247.45.10:3334/api";

export const SMART_STORE_REFRESH_INTERVAL = 5000;
export const NAVER_PLACE_REFRESH_INTERVAL = 5000;
export const FTC_REFRESH_INTERVAL = 30000;
export const INPUT_SET_TIMEOUT = 300;
export const SMART_STORE_DELAY_RELOAD_TIME = 5000;
export const PAGE_SIZE = 100;
