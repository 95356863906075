import React from "react";
import { useForm } from "react-hook-form";

const DescriptionInput = ({ store, onDescriptionChange }) => {
  const { register, handleSubmit } = useForm();

  return (
    <form className="flex space-x-1" onSubmit={handleSubmit(({ description }) => onDescriptionChange(description, store.id))}>
      <input
        defaultValue={store.description}
        // placeholder="비고를 입력해주세요"
        className="border w-full shadow px-2 py-1"
        {...register("description")}
      />
      <button className="border px-4 py-1 flex-shrink-0 shadow">입력</button>
    </form>
  );
};

export default DescriptionInput;
