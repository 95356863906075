import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

export default () => {
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  return (
    <div
      style={{
        margin: "80px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {/* <span style={{ fontSize: "1rem", marginRight: "10px" }}>
        자료를 로딩 중입니다
      </span> */}
      <Spin indicator={antIcon} />
    </div>
  );
};
