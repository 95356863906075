import Header from "./Header";
import { Layout } from "antd";

const DefaultLayout = ({ children }) => {
  const { Content } = Layout;
  return (
    <Layout className="layout h-screen">
      <Header />

      <Content className="">
        <div className="site-layout-content">{children}</div>
      </Content>
    </Layout>
  );
};

export default DefaultLayout;
