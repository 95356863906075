import React, { useEffect } from "react";
import DefaultLayout from "../components/DefaultLayout";
import { Row, Menu } from "antd";

import "moment/locale/ko";
import { useRecoilValue } from "recoil";
import userState from "../atoms/user";
import { useHistory } from "react-router";

import { Link } from "react-router-dom";

const SmartStorePageLayout = ({ children }) => {
  // const [total, setTotal] = useState(0);
  // const [today, setToday] = useState(0);
  // const [yesterday, setYesterday] = useState(0);
  // const [month, setMonth] = useState(0);

  const history = useHistory();
  const user = useRecoilValue(userState);

  useEffect(() => {
    if (!user.username) {
      // alert("You need to login");
      return history.replace("/login");
    }

    if (!user.smartStore) {
      // alert("You are not allowed.");
      return history.goBack();
    }
  }, [history, user]);

  // const backToLogin = () => {
  //   setUser({
  //     isLoggedIn: false,
  //     naverPlace: false,
  //     smartStore: false,
  //     ftcStore: false,
  //   });
  //   history.replace("/login");
  // };

  // const fetcher = (url, token) =>
  //   axios
  //     .get(url, { headers: { Authorization: "Bearer " + token } })
  //     .then((res) => res.data)
  //     .catch((error) => {
  //       console.log("SMART_STORE_PAGE_LAYOUT", error);
  //       // backToLogin();
  //     });

  // const { data, error } = useSWR(
  //   [`${API_URL}/smartstore/statistics`, localStorage.getItem("token")],
  //   fetcher
  // );

  // useEffect(() => {
  //   if (error) {
  //     backToLogin();
  //     console.log(error);
  //   }

  // if (data) {
  //   setToday(data.today);
  //   setYesterday(data.yesterday);
  //   setMonth(data.month);
  //   setTotal(data.total);
  // }
  // }, [data]);

  return (
    <DefaultLayout>
      {/* <div className="text-xl my-2 font-bold">스마트 스토어 신규 등록 업체</div> */}
      <div className="mb-2">
        <Menu mode="horizontal" defaultSelectedKeys={history.location.pathname}>
          <Menu.Item key="/smartstore">
            <Link to="/smartstore">
              <span className="text-lg my-2">미등록(오늘)</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="/smartstore/withoutproduct/total">
            <Link to="/smartstore/withoutproduct/total">
              <span className="text-lg my-2">미등록(30일)</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="/smartstore/withoutproduct/contact">
            <Link to="/smartstore/withoutproduct/contact">
              <span className="text-lg my-2">미등록(계약)</span>
            </Link>
          </Menu.Item>

          <Menu.Item key="/smartstore/withproduct/today">
            <Link to="/smartstore/withproduct/today">
              <span className="text-lg my-2">등록(오늘)</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="/smartstore/withproduct/total">
            <Link to="/smartstore/withproduct/total">
              <span className="text-lg my-2">등록(30일)</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="/smartstore/withproduct/contact">
            <Link to="/smartstore/withproduct/contact">
              <span className="text-lg my-2">등록(계약)</span>
            </Link>
          </Menu.Item>

          {/* <Menu.Item key="/smartstore/active">
            <Link to="/smartstore/active">
              <span className="text-lg my-2">활성(오늘)</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="/smartstore/active/total">
            <Link to="/smartstore/active/total">
              <span className="text-lg my-2">활성(30일간)</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="/smartstore/active/contact">
            <Link to="/smartstore/active/contact">
              <span className="text-lg my-2">활성(계약)</span>
            </Link>
          </Menu.Item> */}
        </Menu>
      </div>

      <Row gutter={16}>
        {/* <Col span={8}>
          <Card>
            <Statistic title="오늘" value={today} />
          </Card>
        </Col>
        <Col span={8}>
          <Card>
            <Statistic title="어제" value={yesterday} />
          </Card>
        </Col>
        <Col span={8}>
          <Card>
            <Statistic title="이번달" value={month} />
          </Card>
        </Col> */}
        {/* <Col span={6}>
          <Card>
            <Statistic title="총(미등록/등록)" value={total} />
          </Card>
        </Col> */}
      </Row>

      <div>{children}</div>
    </DefaultLayout>
  );
};

export default SmartStorePageLayout;
