import React from 'react'

const StatusButtonGroup = ({ row, status, onStatusChange }) => {
    return (
        <div className="flex space-x-2">
            <button onClick={() => onStatusChange(row, 0)} className={`${!status || status === 0 ? "bg-gray-400 text-white hover:bg-gray-500 rounded" : "border border-gray-200 rounded hover:bg-gray-400 hover:text-white hover:border-gray-400"} px-4 py-1`}>미연락</button>
            <button onClick={() => onStatusChange(row, 1)} className={`${status === 1 ? "bg-blue-400 text-white hover:bg-blue-500 rounded" : "border border-gray-200 rounded hover:bg-blue-400 hover:text-white hover:border-blue-400"} px-4 py-1 `}>연락</button>
            <button onClick={() => onStatusChange(row, 2)} className={`${status === 2 ? "bg-purple-400 text-white hover:bg-purple-500 rounded" : "border border-gray-200 rounded hover:bg-purple-400 hover:text-white hover:border-purple-400"} px-4 py-1 `}>부재중</button>
            <button onClick={() => onStatusChange(row, 3)} className={`${status === 3 ? "bg-red-400 text-white hover:bg-red-500 rounded" : "border border-gray-200 rounded hover:bg-red-400 hover:text-white hover:border-red-400"} px-4 py-1 `}>계약</button>
        </div>
    )
}

export default StatusButtonGroup