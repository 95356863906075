import DefaultLayout from "../components/DefaultLayout";

import React from "react";
import { useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";
import userState from "../atoms/user";
import axios from "axios";
import { API_URL } from "../constants";
import { useForm } from 'react-hook-form'

function LoginPage() {
  const [user, setUser] = useRecoilState(userState);
  const history = useHistory();
  const { register, handleSubmit } = useForm();


  const onLogin = async (values) => {
    let { username, password } = values;

    username = username.toLowerCase().trim();
    try {
      const response = await axios.post(`${API_URL}/login`, {
        username,
        password,
      });

      const {
        success,
        message,
        naverPlaceSlow,
        naverPlace,
        smartStore,
        smartStoreActive,
        ftcStore,
        token,
        smartStoreLevel,
      } = response.data;

      if (success) {
        localStorage.setItem("token", token);
        setUser({
          isLoggedIn: true,
          username,
          // naverPlaceSlow,
          naverPlace,
          smartStore,
          smartStoreActive,
          ftcStore,
          smartStoreLevel,
        });
        history.push(
          "/"
          // naverPlace
          //   ? "/naverplace"
          //   : smartStore
          //     ? "/smartstore"
          //     : smartStoreActive
          //       ? "/smartstore/active"
          //       : ftcStore
          //         ? "/ftc"
          //         : naverPlaceSlow
          //           ? "/naverplace_slow"
          //           : ""
        );
        // alert("로그인 성공");
      } else {
        setUser({
          isLoggedIn: false,
          naverPlace: false,
          smartStore: false,
          ftcStore: false,
        });
        alert(message);
      }
    } catch (e) {
      console.log(e);
    }
  };



  return (
    <div className="bg-gray-800 h-screen">
      <div className="flex flex-col justify-start py-40 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h1 className="text-2xl text-white mb-4">LOGIN</h1>
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form className="space-y-6" action="#" method="POST" onSubmit={handleSubmit(onLogin)}>
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  IDENTIFICATION
                </label>
                <div className="mt-1">
                  <input
                    {...register("username", { required: true })}
                    id="username"
                    name="username"
                    type="username"
                    autoComplete="username"
                    required
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                  PASSWORD
                </label>
                <div className="mt-1">
                  <input
                    {...register("password", { required: true })}
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md border border-transparent bg-gray-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                >
                  LOGIN
                </button>
              </div>
            </form>

          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
