import React, { useState, useEffect } from "react";
import SmartStorePageLayout from "./SmartStorePageLayout";
import axios from "axios";
import {
  API_URL,
  INPUT_SET_TIMEOUT,
  SMART_STORE_REFRESH_INTERVAL
} from "../constants";
import {
  Table,
  Radio,
  Input,
  message,
  Tooltip,
  DatePicker,
  Button,
  Row,
  Col,
  Card,
  Statistic
} from "antd";

import moment from "moment";
import "moment/locale/ko";
import useSWR from "swr";
import { useRecoilState } from "recoil";
import userState from "../atoms/user";
import { useHistory } from "react-router";
import { ContractStatus } from "../components/ContractStatus";
import { getUniqueById } from "../utils";
import LoadingIndicator from "../components/LoadingIndicator";
import ClickToCall from "../components/ClickToCall";
import HiddenIframe from "../components/HiddenIframe";
import { fetcher } from "../utils/swr";

const SmartStoreWithProductContact = () => {
  const { RangePicker } = DatePicker;

  const [stores, setStores] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [filteredStores, setFilteredStores] = useState([]);
  const [selectedStartDate, setSelectedStartDate] = useState("");
  const [selectedEndDate, setSelectedEndDate] = useState("");
  //   const [selectedDate1, setSelectedDate1] = useState("");

  const history = useHistory();
  const [user, setUser] = useRecoilState(userState);


  const [iframeUrl, setIframeUrl] = useState(null);


  const backToLogin = () => {
    setUser({
      isLoggedIn: false,
      naverPlace: false,
      smartStore: false,
      ftcStore: false
    });
    message.error("로그인이 만료되었습니다. 다시 로그인해주세요.");
    history.replace("/login");
  };

  const handleSearch = async () => {
    let tempFilteredStoresTotal = stores;

    if (selectedStartDate && selectedEndDate) {
      tempFilteredStoresTotal = tempFilteredStoresTotal.filter(
        (place) =>
          moment(place.created_at).isSameOrAfter(selectedStartDate, "day") &&
          moment(place.created_at).isSameOrBefore(selectedEndDate, "day")
      );
    }

    if (searchKeyword) {
      tempFilteredStoresTotal = tempFilteredStoresTotal.filter((place) =>
        place.store_name.includes(searchKeyword) || place.phone_number === searchKeyword
      );
    }

    setFilteredStores(tempFilteredStoresTotal);
  };

  useEffect(() => {
    handleSearch();
  }, [selectedStartDate, selectedEndDate, stores]);


  const { data, error, mutate } = useSWR(
    `smartstore/withproduct/contact`,
    fetcher,
    {
      // refreshInterval: SMART_STORE_REFRESH_INTERVAL
    }
  );

  useEffect(() => {
    if (error) {
      backToLogin();
      console.log(error);
    }

    if (data) {
      // let newStores = data.stores;
      setStores(getUniqueById(data.stores));
      handleSearch();
      // setFilteredStores(newStores);
    }
  }, [data]);

  const columns = [
    {
      title: "상호명",
      dataIndex: "store_name",
      key: "store_name",
      render: (name, row) => (
        <a
          href={"https://smartstore.naver.com/" + row.store_url}
          target="_blank"
          rel="noreferrer"
        >
          <span>{name}</span>
        </a>
      )
    },
    {
      title: "전화번호",
      dataIndex: "phone_number",
      key: "phone_number",
      render: (tel) => (
        <Tooltip title={tel} overlayInnerStyle={{ fontSize: "2em" }}>
          <ClickToCall setIframeUrl={setIframeUrl} tel={tel} />
        </Tooltip>
      )
    },
    {
      title: "통신판매",
      dataIndex: "online_marketing_number",
      key: "online_marketing_number",
      render: (online_marketing_number) => (
        <div>{online_marketing_number}</div>
      )
    },
    {
      title: "사업자등록번호",
      dataIndex: "identity",
      key: "identity",
      render: (identity) => (
        <div>{identity}</div>
      )
    },
    {
      title: "비고",
      dataIndex: "description",
      key: "description",
      render: (description, row) => (
        <div style={{ display: "flex" }}>
          <Input placeholder="" value={description} disabled />
          <Button
            onClick={async () => {
              const description = prompt("비고 사항을 입력하세요.");
              const checkedData = stores.map((store) =>
                store.id === row.id
                  ? { ...store, description: description || "" }
                  : store
              )
              mutate({ "stores": checkedData }, false)
              setStores(checkedData);
              await axios.post(
                `${API_URL}/smartstore/withproduct/check-description`,
                {
                  id: row.id,
                  description: description || ""
                },
                {
                  headers: {
                    authorization: `Bearer ${localStorage.getItem("token")}`
                  }
                }
              );
            }}
          >
            입력
          </Button>
        </div>
      )
    },
    {
      title: "등록시간",
      dataIndex: "created_at",
      key: "created_at",
      align: "center",
      // defaultSortOrder: "descend",
      sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
      render: (date) => (
        <>
          <div>{moment(date).format("YY/MM/DD")}</div>
          <div>{moment(date).format("HH:mm:ss")}</div>
        </>
      )
    },
    {
      title: "연락시간",
      dataIndex: "checked_at",
      key: "checked_at",
      align: "center",
      defaultSortOrder: "descend",
      sorter: (a, b) => new Date(a.checked_at) - new Date(b.checked_at),
      render: (date) => (
        <>
          <div>{moment(date).format("YY/MM/DD")}</div>
          <div>{moment(date).format("HH:mm:ss")}</div>
        </>
      )
    }
  ];

  return (
    <SmartStorePageLayout>
      <HiddenIframe url={iframeUrl} />
      <ContractStatus stores={stores} />
      <div className="flex my-2 space-x-2">
        <RangePicker
          style={{ minWidth: 300 }}
          onChange={(dateRange) => {
            if (dateRange) {
              const startDate = dateRange[0];
              const endDate = dateRange[1];
              if (startDate && endDate) {
                setSelectedStartDate(startDate);
                setSelectedEndDate(endDate);
              } else {
                setSelectedStartDate("");
                setSelectedEndDate("");
              }
            } else {
              setSelectedStartDate("");
              setSelectedEndDate("");
            }
          }}
          value={
            selectedStartDate && selectedEndDate
              ? [moment(selectedStartDate), moment(selectedEndDate)]
              : ["", ""]
          }
        />

        <Input
          placeholder="검색어를 입력하세요."
          onChange={(e) => {
            setSearchKeyword(e.target.value.trim());
          }}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              handleSearch();
            }
          }}
        />
        <Button onClick={handleSearch}>검색</Button>
      </div>
      {data ?
        <Table
          size="small"
          dataSource={filteredStores}
          columns={columns}
          rowKey={(record) => record.id}
          pagination={{ pageSize: 100 }}
        />
        : <LoadingIndicator />
      }
    </SmartStorePageLayout>
  );
};

export default SmartStoreWithProductContact;
