import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  API_URL,
  PAGE_SIZE,
} from "../constants";
import { Table, message } from "antd";

import moment from "moment";
import "moment/locale/ko";
import useSWR from "swr";
import { useRecoilState } from "recoil";
import userState from "../atoms/user";
import { useHistory } from "react-router";
import SmartStorePageLayout from "./SmartStorePageLayout";
import { ContractStatus } from "../components/ContractStatus";
import { getUniqueById } from "../utils";
import HiddenIframe from "../components/HiddenIframe";
import ClickToCall from "../components/ClickToCall";
import { fetcher } from "../utils/swr";
import StatusButtonGroup from "../components/StatusButtonGroup";
import DateTimeWithFromNow from "../components/DateTimeWithFromNow";
import { CheckCircleOutlined } from "@ant-design/icons";
import io from 'socket.io-client';
import { toast } from "react-toastify";
import DescriptionInput from "../components/DescriptionInput";
const socket = io("http://49.247.45.10:3000");

const SmartStoreWithoutProductTodayPage = () => {
  const history = useHistory();

  const [stores, setStores] = useState([]);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useRecoilState(userState);
  const [iframeUrl, setIframeUrl] = useState(null);
  const [isAutoCall, setIsAutoCall] = useState(false);

  const onDescriptionChange = async (description, id) => {
    const checkedData = stores.map((store) =>
      store.id === id ? { ...store, description: description } : store
    );
    setStores(checkedData);
    await axios.post(
      `${API_URL}/smartstore/withoutproduct/check-description`,
      {
        id: id,
        description: description,
      },
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    toast.info("비고가 입력되었습니다.", {
      position: "bottom-right",
    })
  }

  useEffect(() => {
    socket.on('connect', () => {
      console.log("SOCKET CONNECTED")
    });

    socket.on('disconnect', () => {
      toast.error("실시간 데이터 연결이 끊겼습니다. 새로고침을 해주세요.")
      console.log("SOCKET DISCONNECTED")
    });

    // socket.emit("login", { token: localStorage.getItem("token"), username: user.username })

    socket.on('INSERT', (data) => {
      if (isAutoCall) {
        console.log("COLLECT-CALL-WORKS")
        // axios.get(`http://127.0.0.1:7071/clickcall?TelNo=${data.phone_number.replace(/-/gi, "")}`)
        setIframeUrl(`http://127.0.0.1:7071/clickcall?TelNo=${data.phone_number.replace(/-/gi, "")}`)
      }
      // const diff = moment(data.created_at).diff(moment(data.registered_at), "seconds")
      // if (diff < 10) {
      // toast.error(
      //   <ClickToCall setIframeUrl={setIframeUrl} tel={data.phone_number} />, {
      //   position: "bottom-right",
      //   autoClose: 3000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: false,
      //   progress: undefined,
      //   theme: "colored",
      // });

      // }
      setStores(prev => getUniqueById([data, ...prev]))
      // console.log(data)
    });
    // socket.on('STATUS_REMOVE', (data) => {
    //   setStores(prev => prev.map(store => {
    //     if (store.id === data.id) {
    //       return { ...store, status: 0 }
    //     }
    //     return store;
    //   }))
    // });
    socket.on('STATUS_CHANGE', (data) => {
      // console.log('STATUS_CHANGE', (data))
      setStores(prev => prev.map(store => {
        if (store.id === data.id) {
          return { ...store, status: data.status, description: data.description }
        }
        return store;
      }))
    });
    socket.on('STATUS_INSERT', (data) => {
      // console.log('STATUS_INSERT', (data))
      setStores(prev => prev.map(store => {
        if (store.id === data.id) {
          return { ...store, status: data.status, description: data.description }
        }
        return store;
      }))
      // console.log(data)
    });

    return () => {
      socket.off('connect');
      socket.off('disconnect');
      socket.off('INSERT');
      // socket.off('STATUS_REMOVE');
      socket.off('STATUS_CHANGE');
      socket.off('STATUS_INSERT');
    };
  }, [isAutoCall]);

  const backToLogin = () => {
    setUser({
      isLoggedIn: false,
      naverPlace: false,
      smartStore: false,
      ftcStore: false
    });
    message.error("로그인이 만료되었습니다. 다시 로그인해주세요.");
    history.replace("/login");
  };

  const onStatusChange = async (row, status) => {
    if (status === 3) {
      if (!window.confirm("계약 업체가 확실합니까?")) {
        return;
      }
    }

    const checkedData = stores.map((store) =>
      store.id === row.id
        ? { ...store, status: status }
        : store
    )
    // mutate(`smartstore/withoutproduct/stores`, { stores_without_product: checkedData })
    try {
      setLoading(true)
      await axios.post(
        `${API_URL}/smartstore/store_without_product/check`,
        {
          id: row.id,
          status: status
        },
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem("token")}`
          }
        }
      );
      setStores(checkedData);
    } catch (e) {
      console.error(e);
    } finally {
      // setTimeout(() => {
      setLoading(false)
      // }, SMART_STORE_DELAY_RELOAD_TIME);
    }
  }

  // const { mutate } = useSWRConfig();

  const { data, error } = useSWR(`smartstore/withoutproduct/stores`, fetcher,
    {
      // refreshInterval: 100
    }
  );

  useEffect(() => {
    if (error) {
      backToLogin();
      console.log(error);
    }

    if (data && data.stores_without_product && !loading) {
      // console.log("[LOAD]", data.stores_without_product.length)
      setStores(getUniqueById(data.stores_without_product));
    }
  }, [data]);

  const columnsWithoutProduct = [
    {
      title: "",
      dataIndex: "registered_at",
      key: "registered_at",
      align: "center",
      render: (registered_at, row) => {
        if (!registered_at) {
          return <></>
        }

        const diff = moment(row.created_at).diff(moment(registered_at), "milliseconds") / 1000
        return (
          <div className="">
            {diff >= 0 && diff < 10 && <div className="flex items-center justify-center space-x-2"><CheckCircleOutlined />
              {/* <span>{diff}</span> */}
            </div>}
          </div>
        )
      }
    },
    {
      title: "상호명",
      dataIndex: "store_name",
      key: "store_name",
      render: (name, row) => (
        <a
          href={"https://smartstore.naver.com/" + row.store_url}
          target="_blank"
          rel="noreferrer"
        >
          <span>{name}</span>
        </a>
      )
    },
    {
      title: "상태",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (status, row) => (
        <div className="flex justify-center">
          <StatusButtonGroup status={status} row={row} onStatusChange={onStatusChange} />
        </div>
      )
    },
    {
      title: "전화번호",
      dataIndex: "phone_number",
      key: "phone_number",
      align: "center",
      render: (tel) => (
        <div>
          <ClickToCall setIframeUrl={setIframeUrl} tel={tel} />
        </div>
      )
    },
    // {
    //   title: "통신판매",
    //   dataIndex: "online_marketing_number",
    //   key: "online_marketing_number",
    //   align: "center",
    //   render: (online_marketing_number) => (
    //     <div>{online_marketing_number ? online_marketing_number : "-"}</div>
    //   )
    // },
    {
      title: "사업자등록번호",
      dataIndex: "identity",
      key: "identity",
      align: "center",
      render: (identity) => (
        <div>{identity ? identity : "-"}</div>
      )
    },
    {
      title: "비고",
      dataIndex: "description",
      key: "description",
      align: "center",
      render: (description, row) => (
        <DescriptionInput store={row} onDescriptionChange={onDescriptionChange}></DescriptionInput>
        // <form className="flex space-x-1" onSubmit={handleSubmit((data) => onDescriptionChange(row, data))}>
        //   <input placeholder="" value={description} className="border w-full shadow" {...register("description")} />
        //   <button className="border px-4 py-1 flex-shrink-0 shadow"
        //   // onClick={onDescriptionChange}
        //   >
        //     입력
        //   </button>
        // </form>
      )
    },
    {
      title: "등록시간",
      dataIndex: "created_at",
      key: "created_at",
      align: "center",
      // defaultSortOrder: "descend",
      // sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
      render: (date) => (
        <DateTimeWithFromNow date={date} />
      )
    },
    // {
    //   title: "연락시간",
    //   dataIndex: "contacted_at",
    //   key: "contacted_at",
    //   align: "center",
    //   // defaultSortOrder: "descend",
    //   // sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
    //   render: (contacted_at, row) => (
    //     contacted_at ?
    //       <DateTimeWithFromCreatedAt createdAt={row.created_at} contactedAt={contacted_at} /> : "-"
    //   )
    // },
  ];

  return (
    <SmartStorePageLayout>
      <div className="flex space-x-2 items-center justify-end">
        <input id="autoFirstCall" type="checkbox" checked={isAutoCall} onChange={(e) => {
          console.log(e.target.checked)
          setIsAutoCall(e.target.checked);
        }} />
        <label htmlFor="autoFirstCall" className="select-none">자동 연결(자리 비움시 반드시 해제하세요)</label>
      </div>
      <HiddenIframe url={iframeUrl} />
      <ContractStatus stores={stores} />
      <Table
        size="small"
        dataSource={stores}
        columns={columnsWithoutProduct}
        rowKey={(record) => record.id}
        pagination={{ pageSize: PAGE_SIZE }}
      />
    </SmartStorePageLayout>
  );
};

export default SmartStoreWithoutProductTodayPage;
