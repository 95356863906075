import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  API_URL,
  SMART_STORE_DELAY_RELOAD_TIME,
  SMART_STORE_REFRESH_INTERVAL,
} from "../constants";
import { Table, Input, message, Button } from "antd";

import moment from "moment";
import "moment/locale/ko";
import useSWR from "swr";
import { useRecoilState } from "recoil";
import userState from "../atoms/user";
import { useHistory } from "react-router";
import SmartStorePageLayout from "./SmartStorePageLayout";
import { ContractStatus } from "../components/ContractStatus";
import { getUniqueById } from '../utils/index'
import LoadingIndicator from "../components/LoadingIndicator";
import ClickToCall from "../components/ClickToCall";
import HiddenIframe from "../components/HiddenIframe";
import { fetcher } from "../utils/swr";
import StatusButtonGroup from "../components/StatusButtonGroup";

const SmartStoreWithProductToday = () => {
  const [stores, setStores] = useState("");
  // const [searchKeyword, setSearchKeyword] = useState("");
  // const [filteredStores, setFilteredStores] = useState([]);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const [user, setUser] = useRecoilState(userState);


  const [iframeUrl, setIframeUrl] = useState(null);


  const backToLogin = () => {
    setUser({
      isLoggedIn: false,
      naverPlace: false,
      smartStore: false,
      ftcStore: false,
    });
    message.error("로그인이 만료되었습니다. 다시 로그인해주세요.");
    history.replace("/login");
  };

  const onStatusChange = async (row, status) => {
    const checkedData = stores.map((store) =>
      store.id === row.id
        ? { ...store, status: status }
        : store
    )
    // mutate({ "stores": checkedData }, false)
    setStores(checkedData);
    setLoading(true)

    try {
      await axios.post(
        `${API_URL}/smartstore/store/check`,
        {
          id: row.id,
          status: status,
        },
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
    } catch (e) {
      console.error(e)
    } finally {
      setTimeout(() => {
        setLoading(false)
      }, SMART_STORE_DELAY_RELOAD_TIME);
    }
  }

  // const handleSearch = async () => {
  //   if (searchKeyword) {
  //     setFilteredStores(
  //       stores.filter((store) =>
  //         store.store_name.includes(searchKeyword.trim())
  //       )
  //     );
  //   } else {
  //     setFilteredStores(stores);
  //   }
  // };

  // useEffect(() => {
  //   handleSearch();
  // }, [stores]);

  // var temp_stores = response.data.stores;
  // temp_stores = temp_stores.filter(
  //   (store) => store.store_url !== "undefined"
  // );


  const { data, error, mutate } = useSWR(
    `smartstore/withproduct/stores`,
    fetcher,
    {
      refreshInterval: SMART_STORE_REFRESH_INTERVAL,
    }
  );

  useEffect(() => {
    if (error) {
      backToLogin();
      console.log(error);
    }
    if (data && !loading) {
      console.log("[LOAD]", data.stores.length)
      setStores(getUniqueById(data.stores));
      // setFilteredStores(data.stores);
      // handleSearch();
    }
  }, [data]);

  const columns = [
    {
      title: "상호명",
      dataIndex: "store_name",
      key: "store_name",
      render: (name, row) => (
        <a
          href={"https://smartstore.naver.com/" + row.store_url}
          target="_blank"
          rel="noreferrer"
        >
          <span>{name}</span>
        </a>
      ),
    },
    {
      title: "상태",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (status, row) => (
        <StatusButtonGroup status={status} row={row} onStatusChange={onStatusChange} />
      ),
    },
    {
      title: "전화번호",
      dataIndex: "phone_number",
      key: "phone_number",
      render: (tel) => (
        <ClickToCall setIframeUrl={setIframeUrl} tel={tel} />
      ),
    },
    // {
    //   title: "이메일",
    //   dataIndex: "email",
    //   key: "email",
    // },
    {
      title: "비고",
      dataIndex: "description",
      key: "description",
      render: (description, row) => (
        <div style={{ display: "flex" }}>
          <Input placeholder="" value={description} disabled />
          <Button
            onClick={async () => {
              const description = prompt("비고 사항을 입력하세요.");
              const checkedData = stores.map((store) =>
                store.id === row.id
                  ? { ...store, description: description || "" }
                  : store
              )
              // mutate({ "stores": checkedData }, false)
              setStores(checkedData);

              setLoading(true)
              try {
                await axios.post(
                  `${API_URL}/smartstore/withproduct/check-description`,
                  {
                    id: row.id,
                    description: description || "",
                  },
                  {
                    headers: {
                      authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                  }
                );
              } catch (e) {
                console.error(e)
              } finally {
                setTimeout(() => {
                  setLoading(false)
                }, SMART_STORE_DELAY_RELOAD_TIME);
              }
            }}
          >
            입력
          </Button>
        </div >
      ),
    },
    {
      title: "등록시간",
      dataIndex: "created_at",
      key: "created_at",
      align: "center",
      defaultSortOrder: "descend",
      sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
      render: (date) => (
        <>
          <div>{moment(date).format("YY/MM/DD")}</div>
          <div>{moment(date).format("HH:mm:ss")}</div>
        </>
      ),
    },
  ];

  return (
    <SmartStorePageLayout>
      <HiddenIframe url={iframeUrl} />
      <ContractStatus stores={stores} />
      {/* <div className="flex my-2 space-x-2">
        <Input
          placeholder="검색어를 입력하세요."
          onChange={(e) => {
            setTimeout(() => {
              setSearchKeyword(e.target.value);
            }, INPUT_SET_TIMEOUT);
          }}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              handleSearch();
            }
          }}
        />
        <Button onClick={handleSearch}>검색</Button>
        <CSVLink
          style={{ display: "flex", alignItems: "center" }}
          headers={[
            { label: "스토어이름", key: "store_name" },
            { label: "전화번호", key: "phone_number" },
            { label: "이메일", key: "email" },
            { label: "등록시간", key: "created_at" },
          ]}
          data={stores}
          filename={moment(Date.now()).format("YYYYMMDD") + ".csv"}
        >
          <DownloadOutlined style={{ fontSize: "1.5rem" }} />
        </CSVLink>
      </div> */}
      {data ?
        <Table
          size="small"
          dataSource={stores}
          columns={columns}
          rowKey={(record) => record.id}
          pagination={{ pageSize: 100 }}
        />
        : <LoadingIndicator />}
    </SmartStorePageLayout>
  );
};

export default SmartStoreWithProductToday;
