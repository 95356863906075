import React, { useState, useEffect } from "react";
import SmartStorePageLayout from "./SmartStorePageLayout";
import axios from "axios";
import {
  API_URL,
  INPUT_SET_TIMEOUT,
  SMART_STORE_REFRESH_INTERVAL
} from "../constants";
import {
  Table,
  Radio,
  Input,
  message,
  Tooltip,
  DatePicker,
  Button
} from "antd";

import moment from "moment";
import "moment/locale/ko";
import useSWR from "swr";
import { useRecoilState } from "recoil";
import userState from "../atoms/user";
import { useHistory } from "react-router";
import { CSVLink } from "react-csv";
import { DownloadOutlined } from "@ant-design/icons";
import { ContractStatus } from "../components/ContractStatus";
import { getUniqueById } from "../utils";
import LoadingIndicator from "../components/LoadingIndicator";
import ClickToCall from "../components/ClickToCall";
import HiddenIframe from "../components/HiddenIframe";
import { fetcher } from "../utils/swr";
import StatusButtonGroup from "../components/StatusButtonGroup";

const SmartStoreWithProductTotal = () => {
  const { RangePicker } = DatePicker;

  const [storesTotal, setStoresTotal] = useState([]);
  const [searchKeywordTotal, setSearchKeywordTotal] = useState("");
  const [filteredStoresTotal, setFilteredStoresTotal] = useState([]);
  const [selectedStartDate, setSelectedStartDate] = useState("");
  const [selectedEndDate, setSelectedEndDate] = useState("");
  //   const [selectedDate1, setSelectedDate1] = useState("");

  const history = useHistory();
  const [user, setUser] = useRecoilState(userState);


  const [iframeUrl, setIframeUrl] = useState(null);


  const backToLogin = () => {
    setUser({
      isLoggedIn: false,
      naverPlace: false,
      smartStore: false,
      ftcStore: false
    });
    message.error("로그인이 만료되었습니다. 다시 로그인해주세요.");
    history.replace("/login");
  };

  const onStatusChange = async (row, status) => {
    const checkedData = filteredStoresTotal.map((store) =>
      store.id === row.id
        ? { ...store, status: status }
        : store
    )
    setStoresTotal(checkedData);

    await axios.post(
      `${API_URL}/smartstore/store/check`,
      {
        id: row.id,
        status: status
      },
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`
        }
      }
    );
  }

  const handleSearch = async () => {
    let tempFilteredStoresTotal1 = storesTotal;

    if (selectedStartDate && selectedEndDate) {
      tempFilteredStoresTotal1 = tempFilteredStoresTotal1.filter(
        (place) =>
          moment(place.created_at).isSameOrAfter(selectedStartDate, "day") &&
          moment(place.created_at).isSameOrBefore(selectedEndDate, "day")
      );
    }

    if (searchKeywordTotal) {
      tempFilteredStoresTotal1 = tempFilteredStoresTotal1.filter((place) =>
        place.store_name.includes(searchKeywordTotal) || place.phone_number === searchKeywordTotal
      );
    }

    setFilteredStoresTotal(tempFilteredStoresTotal1);
  };

  useEffect(() => {
    handleSearch();
  }, [selectedStartDate, selectedEndDate, storesTotal]);


  const { data, error, mutate } = useSWR(
    `smartstore/withproduct/total`,
    fetcher,
    {
      // refreshInterval: SMART_STORE_REFRESH_INTERVAL
    }
  );

  useEffect(() => {
    if (error) {
      backToLogin();
      console.log(error);
    }

    if (data) {
      // let newStores = data.stores.splice(user.smartStoreLevel * 20);
      // newStores = newStores.filter(
      //   (store) => !store.status || store.status === 0
      // );
      setStoresTotal(getUniqueById(data.stores));
      handleSearch();
      // setFilteredStoresTotal(newStores);
    }
  }, [data]);

  const columns = [
    {
      title: "상호명",
      dataIndex: "store_name",
      key: "store_name",
      render: (name, row) => (
        <a
          href={"https://smartstore.naver.com/" + row.store_url}
          target="_blank"
          rel="noreferrer"
        >
          <span>{name}</span>
        </a>
      )
    },
    {
      title: "상태",
      dataIndex: "status",
      key: "status",
      align: "center",
      filters: [
        {
          text: '미사용',
          value: 0,
        },
        {
          text: '사용',
          value: 1,
        },
        {
          text: '부재중',
          value: 2,
        },
        {
          text: '계약',
          value: 3,
        }
      ],
      onFilter: (value, record) => {
        // console.log(value, record)
        if (!record.status) {
          return false;
        }
        return record.status === value
      },
      render: (status, row) => (
        <StatusButtonGroup status={status} row={row} onStatusChange={onStatusChange} />
      )
    },
    {
      title: "전화번호",
      dataIndex: "phone_number",
      key: "phone_number",
      render: (tel) => (
        <ClickToCall setIframeUrl={setIframeUrl} tel={tel} />
      )
    },
    {
      title: "통신판매",
      dataIndex: "online_marketing_number",
      key: "online_marketing_number",
      render: (online_marketing_number) => (
        <div>{online_marketing_number}</div>
      )
    },
    {
      title: "사업자등록번호",
      dataIndex: "identity",
      key: "identity",
      render: (identity) => (
        <div>{identity}</div>
      )
    },
    // {
    //   title: "이메일",
    //   dataIndex: "email",
    //   key: "email"
    // },
    {
      title: "비고",
      dataIndex: "description",
      key: "description",
      render: (description, row) => (
        <div style={{ display: "flex" }}>
          <Input placeholder="" value={description} disabled />
          <Button
            onClick={async () => {
              const description = prompt("비고 사항을 입력하세요.");
              const checkedData = storesTotal.map((store) =>
                store.id === row.id
                  ? { ...store, description: description || "" }
                  : store
              )
              // mutate({ "stores": checkedData }, false)
              setStoresTotal(checkedData);
              await axios.post(
                `${API_URL}/smartstore/withproduct/check-description`,
                {
                  id: row.id,
                  description: description || ""
                },
                {
                  headers: {
                    authorization: `Bearer ${localStorage.getItem("token")}`
                  }
                }
              );
            }}
          >
            입력
          </Button>
        </div>
      )
    },
    {
      title: "등록시간",
      dataIndex: "created_at",
      key: "created_at",
      align: "center",
      defaultSortOrder: "descend",
      sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
      render: (date) => (
        <>
          <div>{moment(date).format("YY/MM/DD")}</div>
          <div>{moment(date).format("HH:mm:ss")}</div>
        </>
      )
    }
  ];

  return (
    <SmartStorePageLayout>
      <HiddenIframe url={iframeUrl} />
      <ContractStatus stores={storesTotal} />
      <div className="flex my-2 space-x-2">
        <RangePicker
          style={{ minWidth: 300 }}
          onChange={(dateRange) => {
            if (dateRange) {
              const startDate = dateRange[0];
              const endDate = dateRange[1];
              if (startDate && endDate) {
                setSelectedStartDate(startDate);
                setSelectedEndDate(endDate);
              } else {
                setSelectedStartDate("");
                setSelectedEndDate("");
              }
            } else {
              setSelectedStartDate("");
              setSelectedEndDate("");
            }
          }}
          value={
            selectedStartDate && selectedEndDate
              ? [moment(selectedStartDate), moment(selectedEndDate)]
              : ["", ""]
          }
        />

        <Input
          placeholder="검색어를 입력하세요."
          onChange={(e) => {
            setSearchKeywordTotal(e.target.value.trim());
          }}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              handleSearch();
            }
          }}
        />
        <Button onClick={handleSearch}>검색</Button>
        <CSVLink
          style={{ display: "flex", alignItems: "center" }}
          headers={[
            { label: "스토어이름", key: "store_name" },
            { label: "전화번호", key: "phone_number" },
            // { label: "이메일", key: "email" },
            { label: "등록시간", key: "created_at" }
          ]}
          data={storesTotal}
          filename={moment(Date.now()).format("YYYYMMDD") + ".csv"}
        // onClick={() =>
        //   alert(
        //     "최근 2만건 자료가 다운됩니다. 추가 자료를 원하시면 관리자에게 문의하세요."
        //   )
        // }
        >
          <DownloadOutlined style={{ fontSize: "1.5rem" }} />
        </CSVLink>
      </div>
      {data ?
        <Table
          size="small"
          dataSource={filteredStoresTotal}
          columns={columns}
          rowKey={(record) => record.id}
          pagination={{ pageSize: 100 }}
        />
        : <LoadingIndicator />}
    </SmartStorePageLayout>
  );
};

export default SmartStoreWithProductTotal;
