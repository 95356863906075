import React, { useState } from 'react'
import { useEffect } from 'react'

const HiddenIframe = ({ url }) => {
    const [iframeUrl, setIframeUrl] = useState(null);

    useEffect(() => {
        if (url) {
            setIframeUrl(url)
            console.log(url)
        }
    }, [url])

    return (
        <iframe
            title="iframe"
            src={iframeUrl}
            height="0"
            width="0"
            frameBorder="0"
        ></iframe>
    )
}

export default HiddenIframe