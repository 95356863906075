import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  API_URL,
  INPUT_SET_TIMEOUT,
  PAGE_SIZE,
  SMART_STORE_REFRESH_INTERVAL
} from "../constants";
import {
  Table,
  Radio,
  Input,
  message,
  Tooltip,
  DatePicker,
  Button
} from "antd";

import moment from "moment";
import "moment/locale/ko";
import useSWR from "swr";
import { useRecoilState } from "recoil";
import userState from "../atoms/user";
import { useHistory } from "react-router";
import SmartStorePageLayout from "./SmartStorePageLayout";
import { ContractStatus } from "../components/ContractStatus";
import { getUniqueById } from "../utils";
import LoadingIndicator from "../components/LoadingIndicator";
import ClickToCall from "../components/ClickToCall";
import HiddenIframe from "../components/HiddenIframe";
import { fetcher } from "../utils/swr";
import DateTimeWithFromNow from "../components/DateTimeWithFromNow";
import DescriptionInput from "../components/DescriptionInput";
import { toast } from "react-toastify";

const SmartStoreWithoutProductContact = () => {
  const { RangePicker } = DatePicker;

  const [stores, setStores] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [filteredStores, setFilteredStores] = useState([]);
  const [selectedStartDate, setSelectedStartDate] = useState("");
  const [selectedEndDate, setSelectedEndDate] = useState("");

  const history = useHistory();
  const [user, setUser] = useRecoilState(userState);


  const [iframeUrl, setIframeUrl] = useState(null);


  const backToLogin = () => {
    setUser({
      isLoggedIn: false,
      naverPlace: false,
      smartStore: false,
      ftcStore: false
    });
    message.error("로그인이 만료되었습니다. 다시 로그인해주세요.");
    history.replace("/login");
  };

  const handleSearch = async () => {
    let tempFilteredStoresTotal = stores;

    if (selectedStartDate && selectedEndDate) {
      tempFilteredStoresTotal = tempFilteredStoresTotal.filter(
        (place) =>
          moment(place.created_at).isSameOrAfter(selectedStartDate, "day") &&
          moment(place.created_at).isSameOrBefore(selectedEndDate, "day")
      );
    }

    if (searchKeyword) {
      tempFilteredStoresTotal = tempFilteredStoresTotal.filter((place) =>
        place.store_name.includes(searchKeyword) || place.phone_number === searchKeyword
      );
    }

    setFilteredStores(tempFilteredStoresTotal);
  };

  useEffect(() => {
    handleSearch();
  }, [selectedStartDate, selectedEndDate, stores]);


  const { data, error, mutate } = useSWR(`smartstore/withoutproduct/contact`, fetcher, {
    // refreshInterval: SMART_STORE_REFRESH_INTERVAL
  }
  );

  useEffect(() => {
    if (error) {
      backToLogin();
      console.log(error);
    }

    if (data) {
      setStores(getUniqueById(data.stores_without_product));
      handleSearch();
    }
  }, [data]);

  const onDescriptionChange = async (description, id) => {
    const checkedData = stores.map((store) =>
      store.id === id ? { ...store, description: description } : store
    );
    setStores(checkedData);
    await axios.post(
      `${API_URL}/smartstore/withoutproduct/check-description`,
      {
        id: id,
        description: description,
      },
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    toast.info("비고가 입력되었습니다.", {
      position: "bottom-right",
    })
  }

  const columns = [
    {
      title: "상호명",
      dataIndex: "store_name",
      key: "store_name",
      render: (name, row) => (
        <a
          href={"https://smartstore.naver.com/" + row.store_url}
          target="_blank"
          rel="noreferrer"
        >
          <span>{name}</span>
        </a>
      )
    },
    // {
    //   title: "상태",
    //   dataIndex: "status",
    //   key: "status",
    //   align: "center",
    //   render: (status, row) => (
    //     <div className="flex space-x-1">
    //       <button onClick={() => onStatusChange(row, 0)} className={`${!status || status === 0 ? "bg-gray-400 text-white hover:bg-gray-500" : "border border-gray-400 hover:bg-gray-400 hover:text-white hover:border-gray-400"} px-4 py-1 rounded-sm`}>미연락</button>
    //       <button onClick={() => onStatusChange(row, 1)} className={`${status === 1 ? "bg-blue-400 text-white hover:bg-blue-500" : "border border-gray-400 hover:bg-blue-400 hover:text-white hover:border-blue-400"} px-4 py-1 rounded-sm `}>연락</button>
    //       <button onClick={() => onStatusChange(row, 2)} className={`${status === 2 ? "bg-purple-400 text-white hover:bg-purple-500" : "border border-gray-400 hover:bg-purple-400 hover:text-white hover:border-purple-400"} px-4 py-1 rounded-sm `}>부재중</button>
    //       <button onClick={() => onStatusChange(row, 3)} className={`${status === 3 ? "bg-red-400 text-white hover:bg-red-500" : "border border-gray-400 hover:bg-red-400 hover:text-white hover:border-red-400"} px-4 py-1 rounded-sm `}>계약</button>
    //     </div>
    //   )
    // },
    {
      title: "전화번호",
      dataIndex: "phone_number",
      key: "phone_number",
      align: "center",
      render: (tel) => (
        <Tooltip title={tel} overlayInnerStyle={{ fontSize: "2em" }}>
          <ClickToCall setIframeUrl={setIframeUrl} tel={tel} />
        </Tooltip>
      )
    },
    {
      title: "통신판매",
      dataIndex: "online_marketing_number",
      key: "online_marketing_number",
      align: "center",
      render: (online_marketing_number) => (
        <div>{online_marketing_number ? online_marketing_number : "-"}</div>
      )
    },
    {
      title: "사업자등록번호",
      dataIndex: "identity",
      key: "identity",
      align: "center",
      render: (identity) => (
        <div>{identity ? identity : "-"}</div>
      )
    },
    {
      title: "비고",
      dataIndex: "description",
      key: "description",
      align: "center",
      render: (description, row) => (
        <DescriptionInput store={row} onDescriptionChange={onDescriptionChange}></DescriptionInput>
      )
    },
    {
      title: "등록시간",
      dataIndex: "created_at",
      key: "created_at",
      align: "center",
      // defaultSortOrder: "descend",
      sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
      render: (date) => (
        <>
          <DateTimeWithFromNow date={date} />
        </>
      )
    },
    {
      title: "연락시간",
      dataIndex: "checked_at",
      key: "checked_at",
      align: "center",
      defaultSortOrder: "descend",
      sorter: (a, b) => new Date(a.checked_at) - new Date(b.checked_at),
      render: (date) => (
        <>
          <DateTimeWithFromNow date={date} />
        </>
      )
    }
  ];

  return (
    <SmartStorePageLayout>
      <HiddenIframe url={iframeUrl} />
      <ContractStatus stores={stores} />
      <div className="flex my-2 space-x-2">
        <RangePicker
          style={{ minWidth: 300 }}
          onChange={(dateRange) => {
            if (dateRange) {
              const startDate = dateRange[0];
              const endDate = dateRange[1];
              if (startDate && endDate) {
                setSelectedStartDate(startDate);
                setSelectedEndDate(endDate);
              } else {
                setSelectedStartDate("");
                setSelectedEndDate("");
              }
            } else {
              setSelectedStartDate("");
              setSelectedEndDate("");
            }
          }}
          value={
            selectedStartDate && selectedEndDate
              ? [moment(selectedStartDate), moment(selectedEndDate)]
              : ["", ""]
          }
        />

        <Input
          placeholder="검색어를 입력하세요."
          onChange={(e) => {
            setSearchKeyword(e.target.value.trim());
          }}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              handleSearch();
            }
          }}
        />
        <Button onClick={handleSearch}>검색</Button>
      </div>
      {data ?
        <Table
          size="small"
          dataSource={filteredStores}
          columns={columns}
          rowKey={(record) => record.id}
          pagination={{ pageSize: PAGE_SIZE }}
        /> : <LoadingIndicator />
      }
    </SmartStorePageLayout>
  );
};

export default SmartStoreWithoutProductContact;
